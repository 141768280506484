@import "../stylesheets/base/variables"
@import "../stylesheets/base/knowa-icons"
@import "../stylesheets/components/pspdfkit-custom-buttons"

.PSPDFKit-Viewport
  background: $app-background

.PSPDFKit-Toolbar
  box-shadow: none
  border-radius: 4px
  border-bottom: 1px solid #E9E9EA

.PSPDFKit-Page-Indicator
  padding: 0 5px
  width: auto !important

  .BaselineUI-Box
    border: 0

  label
    margin-bottom: 0

  input
    border-radius: 3px
    background-color: #F4F4F5

.PSPDFKit-width-sm .PSPDFKit-Page-Indicator
  span:first-child, .BaselineUI-ActionIconButton, input, .BaselineUI-Separator
    display: none

.BaselineUI-Select-Popover ul
  margin-bottom: 0

.PSPDFKit-Note-Annotation-Toolbar-Icons
  display: none

.PSPDFKit-Toolbox
  &.PSPDFKit-Toolbox-NoteIcon
    display: none

  // hide advanced annotation options
  &.PSPDFKit-Toolbox-Opacity
    .PSPDFKit-Tool-Button
      display: none

      & + div[class^="PSPDFKit-"] > div[class^="PSPDFKit-"]
        display: none

  // hide advanced annotation options
  &.PSPDFKit-Toolbox-BlendMode
    .PSPDFKit-Tool-Button
      display: none

      & + div[class^="PSPDFKit-"] > div[class^="PSPDFKit-"]
        display: none


  // show color everytime
  &.PSPDFKit-Toolbox-StrokeColor
    .PSPDFKit-Tool-Button
      display: none

      & + div[class^="PSPDFKit-"] > div[class^="PSPDFKit-"]
        display: flex !important

        & > div[class^="PSPDFKit-"] > div[class^="PSPDFKit-"]
          display: flex !important

  // show color everytime
  &.PSPDFKit-Toolbox-FillColor
    .PSPDFKit-Tool-Button
      display: none

      & + div[class^="PSPDFKit-"] > div[class^="PSPDFKit-"]
        display: flex !important

        & > div[class^="PSPDFKit-"] > div[class^="PSPDFKit-"]
          display: flex !important

  // show color everytime
  &.PSPDFKit-Toolbox-Color
    .PSPDFKit-Tool-Button
      display: none

      & + div[class^="PSPDFKit-"] > div[class^="PSPDFKit-"]
        display: flex

  // show color everytime
  &.PSPDFKit-Toolbox-FillColor
    .PSPDFKit-Tool-Button
      display: none

      & + div[class^="PSPDFKit-"] > div[class^="PSPDFKit-"]
        display: flex

.PSPDFKit-Text-Markup-Inline-Toolbar
  .PSPDFKit-Text-Markup-Inline-Toolbar-Comment
    display: none

.PSPDFKit-Toolbar-DropdownGroup-Ai-dropdown + .BaselineUI-Separator,
.thumbnails-button.d-none + .BaselineUI-Separator
  display: none

.PSPDFKit-Input-Dropdown-Items
  .ai-button
    svg
      path
        fill: var(--PSPDFKit-Toolbar-color)

    &:focus,
    &:hover
      svg
        path
          fill: white

  .PSPDFKit-Input-Dropdown-Item-selected
    .ai-button
      svg
        path
          fill: white

.PSPDFKit-Modal-Backdrop
  position: absolute
